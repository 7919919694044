import React from 'react'
import Table from 'components/Table'
import Button from 'components/Button'
import { useHistory } from 'react-router'

function List({
  headers,
  data,
  title,
  buttons,
  loading,

}) {
  const router=useHistory();
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.date}</td>
          <td>{e.coin}</td>
          <td>{e.price}</td>
          <td>{e.percent}%</td>
          <td>{e.leverage}</td>
          <td>{e.margin?"CROSSED":"ISOLATED"}</td>
          <td>{e.type}</td>
        <td>{e.stopPrice}</td>
            <td>{e.status}</td>
          <td>
            <Button size="sm" onClick={()=>{
              router.push(`/future-order-detail/${e.id}`);
            }}>Orders</Button>
             <Button 
             size="sm" onClick={()=>{
              router.push(`/future-order-detail/${e.id}`);
            }}>Delete</Button>
          </td>
        </tr>
      ))}
    />
  )
}

export default List
