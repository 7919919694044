import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(query) {
  try {
    let response = await getData(`member${query}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getTranscationReport(data) {
  try {
    let response = await getData('member-wallet-transcation-report?search=' + data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function store(data) {
  try {
    let response = await sendData('member/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
export async function update(id, data) {
  try {
    let response = await sendData(`member/${id}`, data, 'PUT')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteData(_id) {
  try {
    await sendData(`member/${_id}`, null, 'DELETE')
    return true
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getEachMember(id) {
  try {
    let response = await getData('member/' + id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getSpotWallet(coin) {
  try {
    let response = await getData('spot/wallets?coin='+coin)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getFutureWallet(coin) {
  try {
    let response = await getData('future/wallets?coin='+coin)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function fetchFundingWalletService() {
  try {
    let response = await getData('future/funding-wallets');
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



export async function transferFromFundingService(type) {
  try {
    let response = await getData('future/transfer-from-funding?type='+type)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function transferToFundingService(type) {
  try {
    let response = await getData('future/transfer-to-funding?type='+type)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function resetPassword(id) {
  try {
    let response = await getData('member/reset-password/' + id)
    NotificationManager.success('Successfully reset password!');
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function openSpotOrdersService(values){
  try {
    let response = await sendData('spot/order_open',values);
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function openFutureOrdersService(values){
  try {
    let response = await sendData('future/order_open',values);
    return response.data
  } catch (e) {
    NotificationManager.error(e.response?.data?.message)
    return false
  }
}



export async function openSellSpotOrderService(values){
  try {
    let response = await sendData('spot/sale_order_open',values);
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function lockWalletService(){
  try {
    let response = await sendData('spot/lock-wallets');
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}


export async function lockFutureWalletService(){
  try {
    let response = await sendData('future/lock-wallets');
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}




export async function fetchLockWalletService(){
  try {
    let response = await getData('spot/lock-wallets');
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function fetchFutureLockWalletService(){
  try {
    let response = await getData('future/lock-wallets');
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



export async function fetchFutureLockWalletDetailService(id){
  try {
    let response = await getData('future/lock-wallet-detail/'+id);
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}




export async function fetchLockWalletDetailService(id){
  try {
    let response = await getData('spot/lock-wallet-detail/'+id);
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteFutureLockWalletService(id){
  try {
    let response = await getData('future/lock-wallets-delete/'+id);
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}






