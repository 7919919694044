import React, { useMemo } from 'react'
import List from './list'
import Button from 'components/Button'
import { useHistory } from 'react-router'
import useMember from 'hooks/useMember'
import { useEffect } from 'react'

function FutureLockWallet() {
  const router = useHistory()
  const { state: memberState, fetchFutureLockWallets,deleteFutureLockWallet } = useMember();

  useEffect(() => {
    async function loadData() {
        await fetchFutureLockWallets()
    }
    loadData();
}, [])

const headers=["No","Date","Status","Actions"]

const isPending=useMemo(()=>{
  return memberState.futureLockWallets.find((futureLockWallet)=>futureLockWallet.status==="PENDING");
},[memberState.futureLockWallets]);

  return (
    <List
      headers={headers}
      data={memberState.futureLockWallets}
      title="Future Wallet Locks"
      loading={false}
      onDelete={async (e) => {
        await deleteFutureLockWallet(e)
      }}
      buttons={
        <>
          {!isPending && <Button
            onClick={() => {
              router.push("create-future-lock-wallets");
            }}
          >
            Add New
          </Button>}
        </>
      }
    />
  )
}

export default FutureLockWallet
