import React from 'react'
import Table from 'components/Table'
import dayjs from 'dayjs'
import Button from 'components/Button'
import { useHistory } from 'react-router'

function List({ headers, data, title, buttons, loading, total,onDelete }) {
  const history = useHistory()
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={e.id}>
          <td>{index + 1}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY')}</td>
          <td>{e.status}</td>
          <td>
            <Button
              size="sm"
              onClick={() => {
                history.push('future-lock-wallets-detail/' + e.id)
              }}
            >
              Detail
            </Button>
            {e.status === 'PENDING' &&   <Button
              color="danger"
              size="sm"
              onClick={()=>onDelete(e.id)}
            >
              Delete
            </Button>}

            {e.status === 'CONFIRM' && (
              <>
                <Button
                  size="sm"
                  onClick={() => {
                    history.push(`/reduce-payments/${e.id}`)
                  }}
                >
                  Payment Taken
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    history.push(`/reduce-child-payments/${e.id}`)
                  }}
                >
                  Payment Given
                </Button>
              </>
            )}
          </td>
        </tr>
      ))}
    />
  )
}

export default List
