import React from 'react'
import {
  store,
  getAll,
  update,
  deleteData,
  getEachMember,
  transferFromFundingService,
  resetPassword,
  getSpotWallet,
  getFutureWallet,
  openSpotOrdersService,
  openSellSpotOrderService,
  fetchLockWalletService,
  lockWalletService,
  openFutureOrdersService,
  lockFutureWalletService,
  fetchLockWalletDetailService,
  fetchFutureLockWalletDetailService,
  fetchFundingWalletService,
  fetchFutureLockWalletService,
  transferToFundingService,
  deleteFutureLockWalletService
} from './../services/memberService'
import { useHistory } from 'react-router'

const initialState = {
  members: [],
  loading: false,
  actionLoading: false,
  member: null,
  spotWallets: [],
  futureWallets: [],
  lockWallets: [],
  futureLockWallets:[],
  lockWalletDetails: [],
  futureLockWalletDetails:[],
  fundingWallets:[],
  total: 0,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MEMBER':
      return {
        ...state,
        members: action.payload.data.members,
        total: action.payload.data.total,
      }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_ACTION_LOADING':
      return { ...state, actionLoading: action.payload }
    case 'SET_MEMBER_SPOT_WALLET':
      return { ...state, spotWallets: action.payload.data }
    case 'SET_MEMBER_FUTURE_WALLET':
      return { ...state, futureWallets: action.payload.data }
    case 'SET_LOCK_WALLETS':
      return { ...state, lockWallets: action.payload.data }
    case 'SET_FUTURE_LOCK_WALLETS':
      return { ...state, futureLockWallets: action.payload.data }

    case 'SET_LOCK_WALLET_DETAIL':
      return { ...state, lockWalletDetails: action.payload.data }
      case 'SET_FUTURE_LOCK_WALLET_DETAIL':
        return { ...state, futureLockWalletDetails: action.payload.data }
      

    case 'SET_EACH_MEMBER':
      return { ...state, member: action.payload.data }
      case 'SET_FUNDING_WALLET':
        return { ...state, fundingWallets: action.payload.data }
      

    default:
      throw new Error()
  }
}

const useMember = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const router=useHistory();

  const fetchMember = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_MEMBER', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeMember = async (values) => {
    await store(values)
  }
  const updateMember = async (id, values) => {
    await update(id, values)
  }
  const deleteMember = async (id) => {
    await deleteData(id)
  }

  const fetchEachMember = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getEachMember(id)
    dispatch({ type: 'SET_EACH_MEMBER', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const resetPasswordMember = async (id) => {
    await resetPassword(id)
  }

  const openSpotOrders = async (values) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await openSpotOrdersService(values)
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const openFutureOrders = async (values) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await openFutureOrdersService(values)
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const openSellSpotOrders = async (values) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await openSellSpotOrderService(values)
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const lockWallets = async (values) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await lockWalletService()
    window.location.reload()
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const lockFutureWallet = async (values) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await lockFutureWalletService()
    router.replace("/future-lock-wallets");
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const fetchLockWallets = async (values) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await fetchLockWalletService()
    dispatch({ type: 'SET_LOCK_WALLETS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchFutureLockWallets = async (values) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await fetchFutureLockWalletService()
    dispatch({ type: 'SET_FUTURE_LOCK_WALLETS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchLockWalletDetail = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await fetchLockWalletDetailService(id)
    dispatch({ type: 'SET_LOCK_WALLET_DETAIL', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchSpotWallet = async (coin = 'USDT') => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getSpotWallet(coin)
    dispatch({ type: 'SET_MEMBER_SPOT_WALLET', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchFutureWallets = async (coin = 'USDT') => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getFutureWallet(coin)
    dispatch({ type: 'SET_MEMBER_FUTURE_WALLET', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const transferFromFunding = async (type) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    await transferFromFundingService(type)
    window.location.reload();
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const transferToFunding = async (type) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    await transferToFundingService(type)
    window.location.reload();
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  

  

  const fetchFutureLockWalletDetail = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await fetchFutureLockWalletDetailService(id)
    dispatch({ type: 'SET_FUTURE_LOCK_WALLET_DETAIL', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchFundingWallet = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await fetchFundingWalletService(id)
    dispatch({ type: 'SET_FUNDING_WALLET', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const deleteFutureLockWallet=async (id)=>{
    await deleteFutureLockWalletService(id);
     window.location.reload();
  }

  

  return {
    fetchMember,
    deleteFutureLockWallet,
    storeMember,
    transferFromFunding,
    updateMember,
    deleteMember,
    fetchEachMember,
    fetchFutureLockWalletDetail,
    resetPasswordMember,
    fetchSpotWallet,
    fetchFutureWallets,
    openSpotOrders,
    openSellSpotOrders,
    fetchFundingWallet,
    lockWallets,
    fetchFutureLockWallets,
    fetchLockWallets,
    fetchLockWalletDetail,
    lockFutureWallet,
    openFutureOrders,
    transferToFunding,
    state: state,
  }
}

export default useMember
