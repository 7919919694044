import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router';
import useMember from 'hooks/useMember';
import List from './list';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';

function FutureBuyAccountList() {
    const router = useHistory();
    const query = queryString.parse(router.location.search)

    const headers = [
        'No',
        'Member',
        'Future Wallet',
        'Useable Amount',
        "Received Coin",
        'Status',
    ]

    const { fetchFutureWallets,openFutureOrders, state: memberState } = useMember();

    const { coin, price, percentage,leverage,margin,limit,stopPrice,type,side,positionSide } = query;


    useEffect(() => {
        async function loadData() {
            await fetchFutureWallets();
        }
        loadData();
    }, [])

    const handleSubmitRequest=async ()=>{
      
        const memberIDs=[];
        
        memberState.futureWallets.forEach((wallet)=>{
           const usabledAmount=(wallet.balance/100)*percentage;
           if (wallet.balance>=10)
                memberIDs.push(wallet.account.id);
        })
      
      

        const body={
            symbol:`${coin}USDT`,
            side:side,
            percentage:percentage,
            price:price,
            leverage:leverage,
            positionSide:positionSide,
            margin:margin,
            coin:coin,
            memberIDs:memberIDs,
            limit:limit,
            type:type,
            stopPrice:stopPrice
        }

        await openFutureOrders(body);
    }

    return (
        <div>
            <List
                headers={headers}
                data={memberState.futureWallets}
                title="User Lists"
                loading={memberState.loading}
                buttons={<></>}
                query={query}
            />
            <div className='d-flex justify-content-end'>
                <Button disabled={memberState.actionLoading || memberState.futureWallets.length===0} onClick={handleSubmitRequest}>Save</Button>
            </div>
        </div>
    )
}

export default FutureBuyAccountList